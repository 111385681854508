import { createTheme } from "@mui/material";
import { green } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: green['A200']
    }
  },
 
});